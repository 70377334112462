import React, { useState, useEffect } from 'react';
import { Link, useParams } from 'react-router-dom';
import HeaderProducts from './HeaderProducts';
import axios from 'axios';
import { BASE_URL, BASE_URL_PUBLIC } from '../Components/config/keys';
import { useTranslation } from 'react-i18next';

const ProductsSearch = () => {
    const { t, i18n } = useTranslation();
    const { id } = useParams();

    const [products, setProducts] = useState(null);

    useEffect(() => {
        async function fetchProducts() {
            try {
                let endpoint = `${BASE_URL}products`;
                if (id) {
                    endpoint = `${BASE_URL}products-by-categorie/${id}?lang=${i18n.language}`;
                }
                const response = await axios.get(endpoint);
                setProducts(response.data.data);
            } catch (error) {
                console.error('Error fetching products:', error);
            }
        }

        fetchProducts();
    }, [id, i18n.language]);

    return (
        <>
            <HeaderProducts />
            <section className="pt-5 pb-5">
                <div className="container pb-5">
                    <h2 className="h4 fw-bolder" style={{ color: '#324061' }}>{t('Product')}</h2>
                </div>
                <div className="container">
                    <div className="row">
                        {products?.map((slide, i) => (
                            <div key={slide.id} className="col-md-4 mb-5">
                                <Link to={`/product_detail/${slide.id}`} className="text-decoration-none">
                                    <div className="" style={{
                                        width: 'auto',
                                        backgroundImage: `url(${BASE_URL_PUBLIC}uploads/photos/articles/${slide.photo})`,
                                        backgroundSize: 'contain',
                                        height: '250px',
                                        backgroundRepeat: 'no-repeat',
                                        backgroundPosition: 'center center'
                                    }} />
                                </Link>
                                <div className="card-body">
                                    <Link to={`/product_detail/${slide.id}`} className="text-decoration-none">
                                        <p className='mb-0 mt-3 fw-bold title_card'>{slide.title}</p>
                                    </Link>
                                    <p dangerouslySetInnerHTML={{ __html: slide.petit_description }}></p>
                                    <Link className="btn-outline-primary px-4 text-decoration-none float-end"
                                        to={`/product_detail/${slide.id}`}
                                        style={{ color: '#04D9D9', border: '1px solid #04D9D9', borderRadius: '15px' }}>
                                        {t('Voir')}
                                    </Link>
                                </div>
                            </div>
                        ))}
                    </div>
                </div>
            </section>
        </>
    );
}

export default ProductsSearch;
