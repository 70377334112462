import React, { useState, useEffect } from 'react';
import { useParams } from 'react-router-dom';
import axios from 'axios';
import { BASE_URL, BASE_URL_PUBLIC } from "../Components/config/keys";
import { useTranslation } from 'react-i18next';

function ProductDetail() {
    const { t, i18n } = useTranslation();
    const { id } = useParams();
    const [product, setProduct] = useState(null);

    useEffect(() => {
        async function fetchProduct() {
            try {
                const response = await axios.get(BASE_URL+'products-by-id/'+id+'?lang='+i18n.language);
                setProduct(response.data.data);
            } catch (error) {
                console.error('Error fetching product:', error);
            }
        }

        fetchProduct();
    }, [id, i18n.language]);

    if (!product) {
        return <div>Loading...</div>;
    }

    return (
        <>
            {product && (
                <div className="container mb-5 mt-4">
                    <div className="col-md-12">
                        <section className="panel">
                            <div className="panel-body row ml-3">
                                <div className="col-md-6 col-sm-12" style={{ width: "auto" }}>
                                    <div className="" style={{ maxWidth: "400px" }}>
                                        {product.photo && (
                                            <img class="img-fluid img-thumbnail" src={BASE_URL_PUBLIC + 'uploads/photos/articles/' + product.photo} alt={product.title} 
                                                style={{ height: "auto" }}
                                            />
                                        )}
                                    </div>
                                </div>
                                <div className="col-md-6  col-sm-12 mt-2 ml-0">
                                    {product.title && (
                                        <h4 className="pro-d-title" style={{fontSize: '18px'}}>
                                            {product.title}
                                        </h4>
                                    )}
                                    {product.description && (
                                        <p dangerouslySetInnerHTML={{ __html: product.description }}></p>
                                    )}
                                </div>
                            </div>
                        </section>
                    </div>
                </div>
            )}
        </>
    );
}

export default ProductDetail;
