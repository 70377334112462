import React, { useState, useEffect } from 'react';
import axios from 'axios';
import Button from 'react-bootstrap/Button';
import Modal from 'react-bootstrap/Modal';
import { useTranslation } from 'react-i18next';
import { BASE_URL } from "../Components/config/keys";
import '../css/ContactStyle.css';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faThumbsUp } from '@fortawesome/free-solid-svg-icons';

const Contact = () => {
    const { t } = useTranslation();
    const [formData, setFormData] = useState({
        full_name: '',
        email: '',
        phone_number: '',
        message: ''
    });
    const [showModal, setShowModal] = useState(false);

    const handleChange = (e) => {
        const { name, value } = e.target;
        setFormData({
            ...formData,
            [name]: value
        });
    };

    const handleSubmit = async (e) => {
        e.preventDefault();
        try {
            await axios.post(`${BASE_URL}contacts`, formData);
            setShowModal(true);
        } catch (error) {
            console.error('Error submitting form:', error);
            setShowModal(true);
        }
    };

    useEffect(() => {
        if (showModal) {
            const timer = setTimeout(() => {
                setShowModal(false);
                // Reset form fields after the modal closes
                setFormData({
                    full_name: '',
                    email: '',
                    phone_number: '',
                    message: ''
                });
            }, 3000);
            return () => clearTimeout(timer);
        }
    }, [showModal]);

    const handleClose = () => {
        setShowModal(false);
        // Reset form fields when modal closes
        setFormData({
            full_name: '',
            email: '',
            phone_number: '',
            message: ''
        });
    };

    const styles = {
        modalHeader: {
            borderBottom: 'none',
            display: 'flex',
            justifyContent: 'center',
            backgroundColor: '#f8f9fa',
            borderTopLeftRadius: '0.3rem',
            borderTopRightRadius: '0.3rem',
        },
        modalTitle: {
            fontSize: '1.8rem',
            color: '#324061',
            fontWeight: 'bold',
            textAlign: 'center',
        },
        modalBody: {
            backgroundColor: '#f8f9fa',
            color: '#324061',
            textAlign: 'center',
            fontSize: '1.2rem',
            borderBottomLeftRadius: '0.3rem',
            borderBottomRightRadius: '0.3rem',
            boxShadow: '0 4px 8px rgba(0, 0, 0, 0.1)',
            padding: '2rem',
        },
        icon: {
            fontSize: '3rem',
            color: '#04D9D9',
            marginBottom: '1rem',
        },
        successMessage: {
            fontSize: '1.2rem',
            color: '#324061',
        },
    };

    return (
        <>
            <section className="bg-light pt-2 pb-5" id="contact_bloc">
                <div className="container px-5">
                    <div className="text-center mb-3">
                        <div className="feature bg-primary bg-gradient text-white rounded-3 mb-3">
                            <i className="bi bi-envelope"></i>
                        </div>
                    </div>
                    <div className="row gx-5 justify-content-center">
                        <div className="col-lg-6">
                            <form id="contactForm" onSubmit={handleSubmit}>
                                <div className="form-floating mb-3">
                                    <input
                                        className="form-control"
                                        id="name"
                                        name="full_name"
                                        value={formData.full_name}
                                        type="text"
                                        placeholder="Enter your name..."
                                        onChange={handleChange}
                                        required
                                    />
                                    <label htmlFor="name">{t('Full name')}</label>
                                </div>
                                <div className="form-floating mb-3">
                                    <input
                                        className="form-control"
                                        id="email"
                                        name="email"
                                        value={formData.email}
                                        type="email"
                                        placeholder="name@example.com"
                                        onChange={handleChange}
                                        required
                                    />
                                    <label htmlFor="email">{t('Email')}</label>
                                </div>
                                <div className="form-floating mb-3">
                                    <input
                                        className="form-control"
                                        id="phone_number"
                                        name="phone_number"
                                        value={formData.phone_number}
                                        type="tel"
                                        onChange={handleChange}
                                        placeholder="(123) 456-7890"
                                    />
                                    <label htmlFor="phone_number">{t('Phone')}</label>
                                </div>
                                <div className="form-floating mb-3">
                                    <textarea
                                        className="form-control"
                                        id="message"
                                        name="message"
                                        value={formData.message}
                                        onChange={handleChange}
                                        placeholder="Enter your message here..."
                                        required
                                        style={{ height: '10rem' }}
                                    />
                                    <label htmlFor="message">{t('Message')}</label>
                                </div>
                                <div className="d-grid">
                                    <button className="btn btn-primary btn-lg" id="submitButton" type="submit">
                                        {t('send')}
                                    </button>
                                </div>
                            </form>

                            {/* Modal */}
                            <Modal show={showModal} onHide={handleClose} centered>
                                <Modal.Header style={styles.modalHeader}></Modal.Header>
                                <Modal.Body style={styles.modalBody}>
                                    <FontAwesomeIcon icon={faThumbsUp} style={styles.icon} />
                                    <p style={styles.successMessage}>Votre message a été envoyé avec succès</p>
                                </Modal.Body>
                            </Modal>
                        </div>
                    </div>
                </div>
            </section>
        </>
    );
};

export default Contact;
